import React from "react";
import { Card, Container } from "react-bootstrap";

const MainPage: React.FC = () => {
  return (
    <Container className="main-page mt-5">
      <h1 className="mb-4">Новости</h1>
      <Card className="p-5 rounded-3 mb-5">
        <p className="fs-6">Дата публикации: 13.12.2022</p>
        <h3 className="mb-4">
          Программное обеспечение Quick Launch включено «Единый реестр
          российских программ для электронных вычислительных машин и баз данных»
          13.12.2022 за номером 15947.
        </h3>
        <h3>
          Страница карточки соответствующей{" "}
          <a
            target="_blank"
            className="text-b"
            href="https://reestr.digital.gov.ru/reestr/1206579/"
          >
            <u>записи реестра</u>
          </a>
        </h3>
        <p className="fs-5 m-0"></p>
      </Card>
      <Card className="p-5 rounded-3 mb-5">
        <p className="fs-6">Дата публикации: 22.11.2022</p>
        <h3 className="mb-4">
          Подана заявка на включение программного обеспечения Quick Launch в
          Федеральную государственную информационную систему «Единый реестр
          российских программ для электронных вычислительных машин и баз
          данных».
        </h3>
        <p className="fs-5 m-0"></p>
      </Card>
    </Container>
  );
};

export default MainPage;
