// import Loadable from 'react-loadable';
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import NomenclaturePage from "./pages/NomenclaturePage";
import InvoicePage from "./pages/InvoicePage";
import SuccessPay from "./pages/SuccessPay";
import Test from "./pages/Test";

import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/app.scss";
import MainLayout from "./layouts/MainLayout";

import UserService from "./services/UserService";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { setUser } from "./redux/user/slice";
import { cartFetch } from "./redux/cart/asyncActions";
import { currencyFetch } from "./redux/currency/asyncActions";
import CartPage from "./pages/CartPage";
import Cookies from "js-cookie";
import { selectUser } from "./redux/user/selectors";
import UserProfilePage from "./pages/UserProfilePage";
import Contacts from "./pages/Contacts";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import dateLocalize from "./utils/dateLocalize";
import ShowCaseDownloadList from "./pages/ShowCaseDownload";
import ClientShop from "./pages/ClientShop";
import OrganizationList from "./pages/OrganizationPage";
import CartOrder from "./pages/CartPage/CartOrder";
import MainPage from "./pages/MainPage";
import News from "./pages/News";
// import ClientLicSchemeList from "./pages/ClientLicScheme";
import Terms from "./pages/Terms";
import Loader from "./components/Loader/Loader";

// const Cart = Loadable({
//   loader: () => import(/* webpackChunkName: "Cart" */ './pages/Cart'),
//   loading: () => <div>Идёт загрузка корзины...</div>,
// });
// const NomenclatureFull = React.lazy(() => import(/* webpackChunkName: "NomenclatureFull" */ './pages/NomenclatureFull'));
// const NotFound = React.lazy(() => import(/* webpackChunkName: "NotFound" */ './pages/NotFound'));
dateLocalize();
function App() {
  const [loading, setLoading] = React.useState(true);
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(currencyFetch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const cookie_accessToken = Cookies.get("accessToken");
    if (cookie_accessToken) {
      localStorage.setItem("token", cookie_accessToken);
    }
    Cookies.remove("accessToken");

    const token = localStorage.getItem("token");
    if (token && !user) {
      UserService.checkLogin()
        .then((res) => {
          dispatch(setUser(res.data));
          dispatch(cartFetch());
        })
        .catch((e) => {
          dispatch(setUser(null));
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route path="order" element={<CartOrder />} />
      <Route path="/" element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <MainPage />
            </Suspense>
          }
        />
        <Route path="showcasedownload" element={<ShowCaseDownloadList />} />

        <Route
          path="shop"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <ClientShop />
            </Suspense>
          }
        />
        <Route
          path="news"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <News />
            </Suspense>
          }
        />
        <Route
          path="nomenclature/:id"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <NomenclaturePage />
            </Suspense>
          }
        />

        <Route
          path="test"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <Test />
            </Suspense>
          }
        />

        <Route
          path="contacts"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <Contacts />
            </Suspense>
          }
        />
        <Route
          path="privacy"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="terms"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <Terms />
            </Suspense>
          }
        />

        {user && (
          <>
            <Route
              path="userprofile"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <UserProfilePage />
                </Suspense>
              }
            />
            <Route
              path="invoice"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <InvoicePage />
                </Suspense>
              }
            />
            <Route
              path="organization"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <OrganizationList />
                </Suspense>
              }
            />
            {/* <Route
              path="license"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <ClientLicSchemeList />
                </Suspense>
              }
            /> */}{" "}
            {/* ожидает бэкэнда */}
            <Route
              path="cart"
              element={
                <Suspense fallback={<div>Идёт загрузка корзины...</div>}>
                  <CartPage />
                </Suspense>
              }
            />
            <Route
              path="success_pay"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <SuccessPay />
                </Suspense>
              }
            />
          </>
        )}
        <Route
          path="*"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <NotFound />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
