import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header";

const MainLayout: React.FC = () => {
  const location = useLocation();
  return (
    <div className="wrapper">
      <Header />
      <Outlet />
      {location.pathname === "/" && <Footer />}
    </div>
  );
};

export default MainLayout;
