import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logoutUser } from "../../redux/user/asyncActions";

import { selectUser } from "../../redux/user/selectors";
import LoginModal from "../LoginModal";
import userprofile from "../../assets/img/svg/userprofile.svg";
import cart from "../../assets/img/svg/cart.svg";
import invoice from "../../assets/img/svg/invoice.svg";
import logout from "../../assets/img/svg/logout.svg";
import userheader from "../../assets/img/svg/userheader.svg";
import organization from "../../assets/img/svg/organization.svg";
import license from "../../assets/img/svg/license.svg";
import Cookies from "js-cookie";
import { Badge, Button, Col, Container, Nav, Row } from "react-bootstrap";
import classNames from "classnames";
import OffcanvasModule from "./OffcanvasModule";
import { selectCart } from "../../redux/cart/selectors";

export const Header: React.FC = () => {
  const [isLoginShow, setIsLoginShow] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);
  // const isMounted = React.useRef(false);
  const { items } = useAppSelector(selectCart);
  const totalCount = items.reduce((sum: number, item: any) => sum + item.cnt, 0);
  const location = useLocation();

  // React.useEffect(() => {
  //   if (isMounted.current) {
  //     const json = JSON.stringify(items);
  //     localStorage.setItem('cart', json);
  //   }
  //   isMounted.current = true;
  // }, [items]);

  const onLoginClick = () => {
    setIsLoginShow(!isLoginShow);
    // showLogin('#login');
  };

  return (
    <>
      <header className="shadow-lg gx-0" style={{ backgroundColor: "#f7f7f7", borderBottom: "#5b6d7f 1px solid" }}>
        <Row className="align-items-center text-center">
          <Col className="col-3 col-sm-3 d-md-none text-start">
            <OffcanvasModule />
          </Col>
          <Col className="col-6 col-sm-6 d-md-none">
            {location.pathname === "/" && <h2 className="m-0">Главная</h2>}
            {location.pathname === "/showcasedownload" && <h2 className="m-0">Загрузки</h2>}
            {location.pathname === "/shop" && <h2 className="m-0">Магазин</h2>}
            {location.pathname === "/privacy" && <h2 className="m-0">Лицензионное соглашение</h2>}
            {location.pathname === "/news" && <h2 className="m-0">Новости</h2>}
            {location.pathname === "/device" && <h2 className="m-0">Мои устройства</h2>}
            {location.pathname === "/downloadlog" && <h2 className="m-0">Журнал загрузок</h2>}
            {location.pathname === "/cart" && <h2 className="m-0">Корзина</h2>}
            {location.pathname === "/contacts" && <h2 className="m-0">Контакты</h2>}
            {location.pathname === "/userprofile" && <h2 className="m-0">Параметры аккаунта</h2>}
            {location.pathname === "/invoice" && <h2 className="m-0">Мои заказы</h2>}
            {location.pathname === "/organization" && <h2 className="m-0">Мои юр. лица</h2>}
            {location.pathname === "/license" && <h2 className="m-0">Мои лицензии</h2>}
          </Col>
          <Col xs={3} sm={3}></Col>
        </Row>
        <Container className="top-hat" style={{ fontSize: "14px" }}>
          <Row className=" align-items-center text-center">
            <Col className="d-none d-md-block col-md-5 col-lg-5 col-xl-5 me-auto">
              <Link className="d-flex align-items-center" to="/">
                <img width={70} height={70} src="/images/negotsiantlogo.png" className="logo" alt="LOGO" />
                <h2 className="logo-label fw-bold m-0">OOO "НЕГОЦИАНТ"</h2>
              </Link>
            </Col>

            <Col className="d-none d-md-block col-md-7 col-lg-7 col-xl-6 menu">
              <div className="d-flex text-center align-items-center justify-content-end">
                {!user && (
                  <Col className="col-4 col-sm-4 col-md-5 col-lg-3 col-xl-2 d-flex align-items-center justify-content-end p-2">
                    <Button className="style-btn-action d-flex align-items-center p-2 login-btn" type="button" style={{ border: 0, fontSize: "14px" }} onClick={onLoginClick}>
                      <img alt="userheader" className="me-2" src={userheader} />
                      Вход
                    </Button>
                  </Col>
                )}
                {user && (
                  <>
                    <Col className="col-4 col-sm-4 col-md-8 col-lg-7 col-xl-5 d-flex align-items-center p-2  justify-content-end">
                      <Link className="align-items-center dropdown-item me-5" to="/cart">
                        <img alt="cart" className="me-2" src={cart} />
                        <span className="fs-6 text-b">Корзина</span>
                        <Badge className="position-absolute" bg="danger" pill style={{ top: "9px", fontSize: "12px" }}>
                          {totalCount}
                        </Badge>
                      </Link>
                      <div className="dropdown-center">
                        <Button className="d-flex align-items-center style-btn-action" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <img alt="userheader" className="me-2" src={userheader} />
                          {user.email}
                        </Button>
                        <ul className="dropdown-menu p-0">
                          <li style={{ cursor: "pointer" }}>
                            <Link to="userprofile" className="dropdown-item py-3">
                              <img alt="userprofile" className="me-2" src={userprofile} />
                              <span className="dropdown-link-label text-b">Параметры аккаунта</span>
                            </Link>
                          </li>
                          <li>
                            <hr className="dropdown-divider m-0" />
                          </li>
                          <li style={{ cursor: "pointer" }}>
                            <Link className="dropdown-item py-3" to="/invoice">
                              <img alt="invoice" className="me-2" src={invoice} />
                              <span className="dropdown-link-label text-b">Мои заказы</span>
                            </Link>
                          </li>
                          <li>
                            <hr className="dropdown-divider m-0" />
                          </li>
                          <li style={{ cursor: "pointer" }}>
                            <Link className="dropdown-item py-3" to="/organization">
                              <img alt="organization" className="me-2" src={organization} />
                              <span className="dropdown-link-label text-b">Мои юр.лица</span>
                            </Link>
                          </li>
                          <hr className="dropdown-divider m-0" />
                          <li style={{ cursor: "pointer" }}>
                            <Link className="dropdown-item py-3" to="/license">
                              <img alt="organization" className="me-2" src={license} />
                              <span className="dropdown-link-label text-b">Мои лицензии</span>
                            </Link>
                          </li>
                          <li>
                            <hr className="dropdown-divider m-0" />
                          </li>
                          <li>
                            <a
                              href="/"
                              className="dropdown-item py-3"
                              onClick={(e) => {
                                dispatch(logoutUser());
                                localStorage.removeItem("token");
                                Cookies.remove("accessToken");
                                window.location.href = "/";
                              }}
                            >
                              <img alt="logout" className="me-2" src={logout} />
                              <span className="dropdown-link-label text-b">Выйти</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <div className="menu d-none d-md-block" style={{ backgroundColor: "#3e4652" }}>
          <Container>
            <Nav className="nav-pills align-items-center" activeKey="/" style={{ height: "49px" }}>
              <Nav.Item>
                <Link className={classNames({ "nav-link-active": location.pathname === "/" })} to="/">
                  Главная
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className={classNames({ "nav-link-active": location.pathname === "/shop" })} to="/shop">
                  Магазин
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className={classNames({ "nav-link-active": location.pathname === "/news" })} to="/news">
                  Новости
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className={classNames({ "nav-link-active": location.pathname === "/privacy" })} to="/privacy">
                  Лицензионное соглашение
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className={classNames({ "nav-link-active": location.pathname === "/showcasedownload" })} to="/showcasedownload">
                  Загрузки
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className={classNames({ "nav-link-active": location.pathname === "/contacts" })} to="/contacts">
                  Контакты
                </Link>
              </Nav.Item>
            </Nav>
          </Container>
        </div>
      </header>

      {isLoginShow && (
        <LoginModal
          handleClose={() => {
            setIsLoginShow(false);
          }}
        />
      )}
    </>
  );
};
