import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import nomenclatureNoImg from "../../assets/img/nomenclature_no_image.png";
import NomenclatureService, { Nomenclature, Characteristic } from "../../services/NomenclatureService";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import config from "../../config";
import { selectCurrency } from "../../redux/currency/selectors";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { selectUser } from "../../redux/user/selectors";
import { selectCartItemById } from "../../redux/cart/selectors";
import { cartAdd } from "../../redux/cart/asyncActions";
import { Button, Carousel, CarouselItem, Col, Table } from "react-bootstrap";
import LoginModal from "../../components/LoginModal";
import Loader from "../../components/Loader/Loader";

const NomenclaturePage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [nomenclature, setNomenclature] = React.useState<Nomenclature | null>(null);
  const dispatch = useAppDispatch();
  const currency = useAppSelector(selectCurrency);
  const [isLoginShow, setIsLoginShow] = useState(false);
  const { user } = useAppSelector(selectUser);
  const onClickAdd = () => {
    dispatch(cartAdd(id));
  };
  const onLoginClick = () => {
    setIsLoginShow(!isLoginShow);
  };

  let { id }: { id?: any } = useParams();
  let addedCount = 0;
  const cartItem = useAppSelector(selectCartItemById(id));
  if (user) {
    addedCount = cartItem?.cnt || 0;
  }

  React.useEffect(() => {
    setLoading(true);
    NomenclatureService.getOne(id)
      .then((res) => {
        if (Object.keys(res.data).length === 0) {
          setError("product not found");
        } else {
          setNomenclature(res.data);
        }
      })
      .catch((e) => {
        // console.log('catch Nomenclature');
        if (e.response) {
          console.log(e.response.data);
          setError(e.response.data);
        } else {
          console.log("Error", e.message);
          setError(e.message);
        }
      })
      .finally(() => {
        // console.log('finally Nomenclature');
        setLoading(false);
      });
  }, [id]);

  function nomenclatureDescription() {
    if (nomenclature && nomenclature.description) return new TextDecoder("utf-8").decode(new Uint8Array(nomenclature.description.data));
    return "";
  }

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <Container className="text-center text-black">{error}</Container>;
  }
  if (nomenclature)
    return (
      <>
        <Container className="mt-5">
          <Row>
            <Col xs={12} className="d-sm-none ">
              <Button className="style-btn-action w-auto col-3 mb-5" onClick={() => navigate(-1)}>
                ← Назад
              </Button>
            </Col>
            <Col xs={12} sm={9} md={10} lg={10} xl={11} className="">
              <h1 className="mb-0">{nomenclature.name}</h1>
              <p>{nomenclature.namefull}</p>
            </Col>
            <Col xs={12} sm={3} md={2} lg={2} xl={1} className="d-none d-sm-block text-end">
              <Button className="style-btn-action w-auto col-3 mb-5" onClick={() => navigate(-1)}>
                ← Назад
              </Button>
            </Col>
          </Row>
          {/* <hr className="text-dark" /> */}
          <Row className="justify-content-between">
            <Col xs={12} sm={12} md={12} lg={5} xl={5} className="">
              {nomenclature.images.length === 0 && (
                <div className="w-50 mx-auto">
                  <img className="d-block w-100" src={nomenclatureNoImg} alt={nomenclature.name} />
                </div>
              )}
              {nomenclature.images.length === 1 && (
                <div className="w-50 mx-auto">
                  <img
                    className="d-block w-100"
                    src={`${config.API_URL}/nomenclature_image/` + nomenclature.images[0].imageid}
                    alt={nomenclature.images[0].name}
                  />
                </div>
              )}
              {nomenclature.images.length > 1 && (
                <Carousel controls={false} variant="dark" className="mx-auto mb-5" data-bs-ride="carousel">
                  {nomenclature.images.map((item) => (
                    <CarouselItem className="carousel-inner" key={item.imageid}>
                      <div className="carousel-item active" data-bs-interval="5000">
                        <img className="d-block w-100 p-5" src={`${config.API_URL}/nomenclature_image/` + item.imageid} alt={item.name} />
                      </div>
                    </CarouselItem>
                  ))}
                </Carousel>
              )}
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              {nomenclature.price && (
                <Row
                  className="align-items-center py-3 px-3"
                  style={{
                    backgroundColor: "#3e4652",
                  }}
                >
                  {!user && (
                    <Col>
                      <Button className="style-btn-link fs-5" onClick={onLoginClick}>
                        В корзину
                      </Button>
                    </Col>
                  )}
                  {user && (
                    <Col>
                      {addedCount > 0 && (
                        <Button className="buy-btn-in-cart fs-5" disabled>
                          В корзине
                        </Button>
                      )}
                      {addedCount === 0 && (
                        <Button className="style-btn-link fs-5" onClick={onClickAdd}>
                          В корзину
                        </Button>
                      )}
                    </Col>
                  )}
                  <Col>
                    <p className="m-0 fs-4 text-white text-end">
                      Цена:{" "}
                      <b className="text-white">
                        {nomenclature.price.toLocaleString()} {currency.nameshort}
                      </b>
                    </p>
                  </Col>
                </Row>
              )}
              <Row>
                {nomenclature.characteristics.length > 0 && (
                  <Table striped hover size="sm" className="mb-5">
                    <thead>
                      <tr>
                        <th scope="col">Параметры лицензии</th>
                        <th scope="col">Значение</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nomenclature.characteristics.map((item: Characteristic, i) => (
                        <tr key={i}>
                          <td>{item.characteristicname}</td>
                          <td>{item.value1}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Row>
            </Col>
          </Row>
          <div className="nomenclature-description my-5 p-5">
            <h2>Описание:</h2>
            <div className="text-dark text-start fs-6" dangerouslySetInnerHTML={{ __html: nomenclatureDescription() }}></div>
          </div>
        </Container>
        {isLoginShow && (
          <LoginModal
            handleClose={() => {
              setIsLoginShow(false);
            }}
          />
        )}
      </>
    );
  return <Container className="text-center">Нет данных</Container>;
};

export default NomenclaturePage;
