import React from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import ClientShopItem from "./ClientShopItem";
import { Pagination } from "../../components/Pagination";
import EmptyBox from "../../components/EmptyBox";
import ShowcaseService, { Showcase } from "../../services/ShowcaseService";
import Loader from "../../components/Loader/Loader";
import windows from "../../assets/img/svg/windows.svg";
import debian from "../../assets/img/svg/debian.svg";
import vscode from "../../assets/img/svg/vs-code.svg";
import python from "../../assets/img/svg/python.svg";
import qtdesigner from "../../assets/img/svg/qt.svg";
import sqllite from "../../assets/img/svg/sqllite.svg";
import pyinstaller from "../../assets/img/svg/py.svg";

const ClientShop: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([] as Showcase[]);
  const [error, setError] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalcount, setTotalcount] = React.useState(0);
  const limit = 16;
  const pageCount = Math.floor(totalcount / limit) + (totalcount % limit ? 1 : 0);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const effectFunc = () => {
    setLoading(true);
    setError("");
    ShowcaseService.getAll(currentPage, limit)
      .then((res) => {
        setItems(res.data.items /* .filter((item) => item.price) */);
        setTotalcount(res.data.totalcount);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.response) {
          console.log(e.response.data);
          setError(e.response.data);
        } else {
          console.log("Error", e.message);
          setError(e.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(effectFunc, [currentPage]);
  if (loading) return <Loader />;

  if (error || !items)
    return (
      <Container className="text-center">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );

  if (items.length === 0) return <EmptyBox />;
  return (
    <Container className="my-5">
      <div className="mx-3">
        <h1>Доступные пакеты лицензий</h1>
        <p className="mt-1">
          Quick Launch – Программа для быстрого доступа к сетевым ресурсам и программам с проверкой прав доступа в соответствии группам
          безопасности. Программа не требует установки, работает по принципу скачал-запустил. Для хранения информации о правах доступа и
          параметрах запуска приложений используется база данных.
        </p>

        <Row>
          <Col xs={12} sm={12} md={6}>
            <p className="mt-1" style={{ display: "flex", alignItems: "center" }}>
              <span className="me-2">Платформа:</span>
              <img className="me-1" src={windows} alt="" width={20} height={20} />
              <span className="me-2">Windows 11,</span>
              <img className="me-1" src={debian} alt="" width={20} height={20} />
              <span className="me-2">Debian</span>
            </p>
            <p className="mt-1" style={{ display: "flex", alignItems: "center" }}>
              <span className="me-2">Среда разработки:</span>
              <img className="me-1" src={vscode} alt="" width={20} height={20} />
              <span>Visual Studio Code</span>
            </p>
            <p className="mt-1" style={{ display: "flex", alignItems: "center" }}>
              <span className="me-2">Язык программирования:</span>
              <img className="me-1" src={python} alt="" width={20} height={20} />
              <span>Python</span>
            </p>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <p className="mt-1" style={{ display: "flex", alignItems: "center" }}>
              <span className="me-2">Дизайн:</span>
              <img className="me-1" src={qtdesigner} alt="" width={20} height={20} />
              <span>QT Designer</span>
            </p>
            <p className="mt-1" style={{ display: "flex", alignItems: "center" }}>
              <span className="me-2">База данных:</span>
              <img className="me-1" src={sqllite} alt="" width={20} height={20} />
              <span>SQLite</span>
            </p>
            <p className="mt-1" style={{ display: "flex", alignItems: "center" }}>
              <span className="me-2">Компиляция:</span>
              <img className="me-1" src={pyinstaller} alt="" width={20} height={20} />
              <span>Pyinstaller</span>
            </p>
          </Col>
        </Row>

        <hr className="text-dark" />
      </div>
      {items.length === 1 && (
        <div className="text-center g-4 w-100">
          <div>
            {items.map((item) => (
              <ClientShopItem key={item.showcaseid} {...item} />
            ))}
          </div>
        </div>
      )}
      {items.length > 1 && (
        <Row className="text-center g-4 mx-auto w-100">
          {items.map((item) => (
            <Col xs={12} sm={12} md={6} lg={4} xl={4} className="g-4 px-4" key={item.showcaseid}>
              <ClientShopItem {...item} />
            </Col>
          ))}
        </Row>
      )}
      {pageCount > 1 && (
        <Container className="p-4">
          <Pagination currentPage={currentPage} onChangePage={onChangePage} pageCount={pageCount} />
        </Container>
      )}
    </Container>
  );
};

export default ClientShop;
