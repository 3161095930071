import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/user/selectors";
import { InvoiceService } from "../../../services/InvoiceService";
import UserProfileService from "../../../services/UserProfileService";

const PeopleContent: React.FC = () => {
  const [people, setPeople] = React.useState<any>({ surname: "", name: "", middlename: "", phone: "" });
  const { user } = useAppSelector(selectUser);

  const makeInvoice = () => {
    InvoiceService.makeFromCart("")
      .then((res: any) => {
        return InvoiceService.makeYookassa(res.data.invoiceid);
      })
      .then((res) => {
        window.location.href = res.data.payment.confirmation.confirmation_url;
      })
      .catch((e: any) => {
        if (e.response) {
          alert(e.response.data.message); // setError(e.response.data.message);
        } else if (e.request) {
          alert(e.request); // setError(e.request);
        } else {
          alert(e.message); // setError(e.message);
        }
      });
  };

  React.useEffect(() => {
    UserProfileService.peopleGet()
      .then((res) => {
        setPeople(res.data);
      })
      .catch((e: any) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log("Error", e.message);
        }
      });
  }, []);

  return (
    <>
      <Row className="justify-content-between">
        <Col className="me-3" xs={12}>
          <input type="text" className="w-100 rounded p-2 my-2 border" readOnly placeholder="ФИО" value={people.surname + " " + people.name + " " + people.middlename} />
          <input type="tel" className="w-100 rounded p-2 my-2 border" readOnly placeholder="Телефон" value={people.phone} />
          <input type="email" className="w-100 rounded p-2 my-2 border" readOnly placeholder="E-mail" value={user?.email} />
        </Col>
      </Row>

      <h2 className="mt-3">Выберите способ оплаты</h2>
      <div className="border border-secondary rounded my-3">
        <Row className="align-items-center btn btn-payment d-flex text-start" htmlFor="flexRadioDefault1">
          <Col xs={1}>
            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" readOnly checked={true} />
          </Col>
          <Col xs={1}>
            <span>ЮMoney</span>
          </Col>
          <Col xs={3}>
            <label className="row  p-0 ps-4 fs-5 text-start w-100 form-check-label ps-2 form-check-label ps-2" htmlFor="flexRadioDefault1">
              <img className="ms-3" src="/images/umoney.png" alt="ЮMoney-icon"></img>
            </label>
          </Col>
        </Row>
      </div>
      <p className="my-3">
        Оплачивая заказ, Вы соглашаетесь с условиями{" "}
        <Link to="/privacy">
          <u>лицензионного соглашения</u>
        </Link>
        .
      </p>
      <Row className="align-items-center">
        <Col className=" me-4" xs={12} md={12} lg={3} xl={3}>
          <Button className="style-btn-action py-3 w-100 fs-4" onClick={makeInvoice}>
            Оплатить заказ
          </Button>
        </Col>
        <Col xs={12} md={12} lg={8} xl={8}>
          <p className="mb-0">Чек будет отправлен на эл. почту {user?.email}</p>
        </Col>
      </Row>
    </>
  );
};

export default PeopleContent;
