import React from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import ShopService, { Organization } from "../../services/ShopService";

type OrganizationItemProps = {
  organization: Organization;
  hasinvoice: boolean;

  deleteCallBack: () => void;
};

const OrganizationItem: React.FC<OrganizationItemProps> = ({ organization, hasinvoice, deleteCallBack }) => {
  enum Status {
    collapsed,
    expanded,
    edit,
  }

  const [modalShow, setModalShow] = React.useState(false);
  const [original, setOriginal] = React.useState({ ...organization } as Organization);
  const [candidat, setCandidat] = React.useState({ ...organization } as Organization);
  const [status, setStatus] = React.useState<Status>(Status.collapsed);

  const cancelUpdateOrganization = () => {
    setCandidat(original);
    setStatus(Status.collapsed);
  };

  const updateOrganization = () => {
    ShopService.organizationUpdate(candidat)
      .then(() => {
        setOriginal(candidat);
        setStatus(Status.collapsed);
      })
      .catch((e: any) => {
        if (e.response) {
          console.error(e.response.data);
        } else {
          console.error("Error", e.message);
        }
      });
  };
  const deleteOrganization = (organizationid: string) => {
    ShopService.organizationDelete(organizationid)
      .then(() => {
        deleteCallBack();
      })
      .catch((e: any) => {
        if (e.response) {
          console.error(e.response.data);
        } else {
          console.error("Error", e.message);
        }
      });
  };
  function showPromtDeleteOrganization() {
    setModalShow(true);
  }

  function PromtDeleteOrganizationModal(props: any) {
    return (
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-b" id="contained-modal-title-vcenter">
            <h4>Вы точно хотите удалить организацию?</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button className="w-100 py-3" variant="danger" onClick={() => deleteOrganization(original.organizationid)}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Card className="p-3 mb-4">
      <div>
        {status === Status.collapsed && (
          <Row className="mb-3 align-items-center">
            <Col xs={1} className="me-3">
              <img src="/images/organization.png" alt="" className="w-100" />
            </Col>
            <Col>
              <h5 className="mb-0">{original.name}</h5>
            </Col>

            {!hasinvoice && <Button className="position-absolute position-absolute top-0 end-0 m-3 btn-close btn-danger" variant="primary" onClick={showPromtDeleteOrganization}></Button>}
            <PromtDeleteOrganizationModal show={modalShow} onHide={() => setModalShow(false)} />
          </Row>
        )}
        {status === Status.edit && (
          <>
            <h3 className="ms-2">Редактирование юр. лица</h3>
            <Row className="mb-3 align-items-center">
              <Col>
                <span className="ms-2 text-b">Наименование:</span>
              </Col>
              <Col xs={12} md={8} lg={6} xl={6}>
                <input type="text" className="form-control" value={candidat.name} onChange={(e) => setCandidat({ ...candidat, name: e.target.value })} />
              </Col>
            </Row>
            <Row className="mb-3 align-items-center">
              <Col>
                <span className="ms-2 text-b">Полное наименование:</span>
              </Col>
              <Col xs={12} md={8} lg={6} xl={6}>
                <input type="text" className="form-control" value={candidat.namefull} onChange={(e) => setCandidat({ ...candidat, namefull: e.target.value })} />
              </Col>
            </Row>
            <Row className="mb-3 align-items-center">
              <Col>
                <span className="ms-2 text-b">ИНН:</span>
              </Col>
              <Col xs={12} md={8} lg={6} xl={6}>
                <input type="text" className="form-control" pattern="[0-9]{10,12}" value={candidat.inn} onChange={(e) => setCandidat({ ...candidat, inn: e.target.value })} />
              </Col>
            </Row>
            <Row className="mb-3 align-items-center">
              <Col>
                <span className="ms-2 text-b">КПП:</span>
              </Col>
              <Col xs={12} md={8} lg={6} xl={6}>
                <input type="text" className="form-control" pattern="[0-9]{10,12}" value={candidat.kpp} onChange={(e) => setCandidat({ ...candidat, kpp: e.target.value })} />
              </Col>
            </Row>
          </>
        )}
        {status === Status.expanded && (
          <>
            <h3 className="ms-1">Расширенная информация юр. лица</h3>
            <Row className="text-dark ms-2 mb-3 align-items-center">
              <Col>
                <span>Наименование:</span>
              </Col>
              <Col xs={12} md={8} lg={6} xl={6}>
                {original.name}
              </Col>
            </Row>
            <Row className="text-dark ms-2 mb-3 align-items-center">
              <Col>
                <span>Полное наименование:</span>
              </Col>
              <Col xs={12} md={8} lg={6} xl={6}>
                {original.namefull}
              </Col>
            </Row>
            <Row className="text-dark ms-2 mb-3 align-items-center">
              <Col>
                <span>ИНН:</span>
              </Col>
              <Col xs={12} md={8} lg={6} xl={6}>
                {original.inn}
              </Col>
            </Row>
            <Row className="text-dark ms-2 mb-3 align-items-center">
              <Col>
                <span>КПП:</span>
              </Col>
              <Col xs={12} md={8} lg={6} xl={6}>
                {original.kpp}
              </Col>
            </Row>
          </>
        )}
      </div>

      <Row>
        <Col xs={12} md={8} lg={6} xl={6} className="ms-auto d-flex justify-content-evenly">
          {status !== Status.edit && (
            <Button className="w-100 style-btn-action" onClick={() => setStatus(Status.edit)}>
              Редактировать
            </Button>
          )}
          {status === Status.collapsed && (
            <Button className="w-100 style-btn-action" onClick={() => setStatus(Status.expanded)}>
              Развернуть
            </Button>
          )}
          {status === Status.edit && (
            <>
              <Button className="w-100 style-btn-action" onClick={updateOrganization}>
                Сохранить
              </Button>
              <Button className="w-100 style-btn-action" onClick={cancelUpdateOrganization}>
                Отменить
              </Button>
            </>
          )}
          {status === Status.expanded && (
            <Button className="w-100 style-btn-action" onClick={() => setStatus(Status.collapsed)}>
              Свернуть
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default OrganizationItem;
