import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';

const Test: React.FC = () => {
  const [login, setLogin] = useState('dzuyba@mail.ru');
  const [str1, setStr1] = useState('str1');
  const [str2, setStr2] = useState('str2');
  React.useEffect(() => {
    console.log('useEffect login', login);
    setStr1(login + '_str1');
  }, [login]);
  React.useEffect(() => {
    console.log('useEffect str1', str1);
    setStr2(login + '_str2');
  }, [str1]);
  React.useEffect(() => {
    console.log('useEffect str2', str2);
  }, [str2]);
  return (
    <Container>
      <h1>{login}</h1>
      <input value={login} onChange={(e) => setLogin(e.target.value)} />
      <h1>{str1}</h1>
      <h1>{str2}</h1>
      <div
        onClick={(e) => {
          setLogin('123');
          setStr1('123');
          setStr2('123');
        }}>
        click
      </div>
    </Container>
  );
};

export default Test;
