import { createAsyncThunk } from "@reduxjs/toolkit";
import { Cart } from "./types";
import cartService from "../../services/CartService";

export const cartFetch = createAsyncThunk<Cart[]>("cart/cartFetch", async () => {
  const res = await cartService.getAll();
  return res.data;
});

export const cartAdd = createAsyncThunk<Cart, string>("cart/cartAdd", async (params) => {
  const res = await cartService.add(params);
  return res.data;
});

export const cartDec = createAsyncThunk<Cart, string>("cart/cartDec", async (params) => {
  const res = await cartService.dec(params);
  return res.data;
});

export const cartDel = createAsyncThunk<Cart, string>("cart/cartDel", async (params) => {
  const res = await cartService.del(params);
  return res.data;
});

export const cartClear = createAsyncThunk("cart/cartClear", async () => {
  const res = await cartService.clear();
  return res.data;
});
