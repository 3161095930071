import React from "react";

import cn from "classnames";
import s from "./Loader.module.scss";
import { Container } from "react-bootstrap";

const Loader: React.FC = () => {
  return (
    <Container className="mt-5">
      <div className={cn("mt-5 mx-auto", s.loader)}>
        <div></div>
        <div></div>
      </div>
    </Container>
  );
};

export default Loader;
