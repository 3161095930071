import React from "react";
import UserProfileService from "../../services/UserProfileService";

const ChangePhoneItem: React.FC = () => {
  const [people, setPeople] = React.useState<any>({ phone: "" });

  React.useEffect(() => {
    UserProfileService.peopleGet()
      .then((res) => {
        setPeople(res.data);
      })
      .catch((e: any) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log("Error", e.message);
        }
      });
  }, []);

  const onClickApply = () => {
    UserProfileService.peopleUpdate(people)
      .then((res) => {
        window.location.reload();
      })
      .catch((e: any) => {
        if (e.response) {
          alert(e.response.data);
        } else {
          alert(e.message);
        }
      });
  };

  return (
    <div>
      <div className="mb-3">
        <h5 className="mb-4">Изменить номер телефона</h5>

        <label className="form-label mb-1">Номер телефона</label>
        <input type="value" className="form-control mb-3" value={people.phone} onChange={(e) => setPeople({ ...people, phone: e.target.value })} />
      </div>

      <button type="submit" className="btn style-btn-action" onClick={onClickApply}>
        Применить
      </button>
    </div>
  );
};

export default ChangePhoneItem;
