import React from "react";
import { Container } from "react-bootstrap";

const MainPage: React.FC = () => {
  return (
    <Container className="main-page mt-5 text-center">
      {/* <img className="w-100 mb-5" src="/images/" alt="front" /> */}
      <h2 className="mb-5">Мы ИТ-компания, работающая на рынке Сибирского региона с 2019 года</h2>
      <h4 className="mb-4">Наши специалисты умеют:</h4>
      <ul className="p-0">
        <li className="animate__animated animate__flipInX">Настраивать корпоративные сети компании любой сложности</li>
        <li className="animate__animated animate__flipInX animate__delay-1s">Выбирать на рынке необходимое оборудование для развития ИТ-Инфраструктуры вашей компании</li>
        <li className="animate__animated animate__flipInX animate__delay-2s">Сопровождать всю ИТ-инфраструктуру в режиме работы вашей компании</li>
        <li className="animate__animated animate__flipInX animate__delay-3s">Оказывать техподдержку пользователям в режиме работы вашей компании</li>
        <li className="animate__animated animate__flipInX animate__delay-4s">Разрабатывать ПО, необходимое для автоматизации процессов в вашей компании</li>
      </ul>
    </Container>
  );
};

export default MainPage;
