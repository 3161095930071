import React, { useState } from "react";
import config from "../../config";
import { Image } from "../../services/ImageService";
import nomenclatureNoImg from "../../assets/img/nomenclature_no_image.png";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/currency/selectors";
import { selectCartItemById } from "../../redux/cart/selectors";
import { cartAdd } from "../../redux/cart/asyncActions";
import { Link } from "react-router-dom";
import { selectUser } from "../../redux/user/selectors";
import LoginModal from "../../components/LoginModal";

export type ClientShopItemProps = {
  showcaseid: string;
  productid: string;
  productname: string;
  nomenclatureid: string;
  nomenclaturename: string;
  price: number;
  images: Image[];
};

const ClientShopItem: React.FC<ClientShopItemProps> = ({ showcaseid, nomenclatureid, nomenclaturename, productname, price, images }) => {
  const currency = useAppSelector(selectCurrency);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);
  const onClickAdd = () => {
    dispatch(cartAdd(nomenclatureid));
  };
  const [isLoginShow, setIsLoginShow] = useState(false);

  const cartItem = useAppSelector(selectCartItemById(nomenclatureid));
  const addedCount = cartItem?.cnt || 0;

  const onLoginClick = () => {
    setIsLoginShow(!isLoginShow);
    // showLogin('#login');
  };

  return (
    <div className="shop-card card h-100 shadow py-4  mx-auto">
      <Link className="my-auto" to={`/nomenclature/${nomenclatureid}`}>
        <img alt="noimg" className="w-50" src={images.length > 0 ? `${config.API_URL}/nomenclature_image/` + images[0].imageid : nomenclatureNoImg} />
      </Link>
      <div className="card-body d-flex flex-column">
        <Link to={`/nomenclature/${nomenclatureid}`}>
          <h4 className="card-title">{nomenclaturename}</h4>
          {/* <p className="card-text">&#10032;&#10032;&#10032;&#10032;&#10032;(3040 отзывов)</p> */}
          <hr className="w-25 mx-auto" />
        </Link>
        <div className="mt-auto">
          <h4>
            <b>{price === null ? "не указана" : price.toLocaleString()}</b> {currency.nameshort}
            /год
          </h4>
          {!user && (
            <button type="button" className="btn style-btn-link mt-3" onClick={onLoginClick}>
              В корзину
            </button>
          )}
          {user && (
            <>
              {addedCount > 0 && price && (
                <button type="button" className="btn buy-btn-in-cart mt-3" disabled>
                  В корзине
                </button>
              )}
              {addedCount === 0 && price && (
                <button type="button" className="btn style-btn-link mt-3" onClick={onClickAdd}>
                  В корзину
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {isLoginShow && (
        <LoginModal
          handleClose={() => {
            setIsLoginShow(false);
          }}
        />
      )}
    </div>
  );
};

export default ClientShopItem;
