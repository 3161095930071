import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { selectUser } from "../../redux/user/selectors";
import downloads from "../../assets/img/svg/downloads.svg";
import sandwich from "../../assets/img/svg/sandwich.svg";
import shop from "../../assets/img/svg/shop.svg";
import useroffcanvas from "../../assets/img/svg/useroffcanvas.svg";
import userprofileoffcanvas from "../../assets/img/svg/userprofileoffcanvas.svg";
import invoiceoffcanvas from "../../assets/img/svg/invoiceoffcanvas.svg";
import cartoffcanvas from "../../assets/img/svg/cartoffcanvas.svg";
import logoutoffcanvas from "../../assets/img/svg/logoutoffcanvas.svg";
import organizationoffcanvas from "../../assets/img/svg/organizationoffcanvas.svg";
import mainoffcanvas from "../../assets/img/svg/mainoffcanvas.svg";
import contactsoffcanvas from "../../assets/img/svg/contactsoffcanvas.svg";
import newsoffcanvas from "../../assets/img/svg/newsoffcanvas.svg";
import privacy from "../../assets/img/svg/privacy.svg";
import licenseoffcanvas from "../../assets/img/svg/licenseoffcanvas.svg";
import Cookies from "js-cookie";

import { logoutUser } from "../../redux/user/asyncActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import LoginModal from "../LoginModal";

function OffcanvasModule() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoginShow, setIsLoginShow] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  const onLoginClick = () => {
    handleClose();
    setIsLoginShow(!isLoginShow);
  };

  return (
    <>
      <Button
        type="button"
        className="btn p-3 sandwich"
        onClick={handleShow}
        style={{
          border: "0",
          backgroundColor: "#3e4652",
          borderRadius: "0",
        }}
      >
        <img alt="sandwich" src={sandwich} />
      </Button>

      <Offcanvas show={show} onHide={handleClose} responsive="md" placement="top" keyboard className="h-100">
        <Offcanvas.Header className="position-absolute px-3 pt-4" closeButton closeVariant="white"></Offcanvas.Header>
        <Offcanvas.Body>
          <div
            className="mt-5 text-center"
            style={{
              position: "absolute",
              left: "0",
              top: "0",
              zIndex: "-1",
            }}
          >
            <img className="w-75 opacity-25" alt="Logo-negotsiant" src="/images/negotsiantlogo.png" />
          </div>
          <div>
            {user && (
              <div className="text-center w-100 pt-3 mb-3">
                <div>
                  <img alt="useroffcanvas" src={useroffcanvas} />
                </div>
                <div>{user.email}</div>
                <div>
                  <Link to="userprofile" className="dropdown-item" onClick={handleClose}>
                    <img alt="userprofileoffcanvas" className="mx-2" src={userprofileoffcanvas} />
                    <span style={{ color: "white" }}>Параметры аккаунта</span>
                  </Link>
                </div>
              </div>
            )}
            {!user && (
              <div className="text-center w-100 pt-3 mb-3">
                <div>
                  <img alt="useroffcanvas" src={useroffcanvas} />
                </div>
                <div>
                  <button className="btn p-0 login-btn-offcanvas" type="button" onClick={onLoginClick} aria-label="Закрыть" style={{ border: 0, color: "white" }}>
                    Логин
                  </button>
                </div>
              </div>
            )}
            <hr />
            <div className="px-5 py-4 pt-0">
              <div className="">
                <Link to="/" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                  <div className="col-2 col-sm-2 col-md-1">
                    <img alt="main" src={mainoffcanvas} />
                  </div>
                  <div className="col-10 col-sm-10 col-md-11">Главная</div>
                </Link>
                <hr />
                <Link to="/shop" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                  <div className="col-2 col-sm-2 col-md-1">
                    <img alt="shop" src={shop} />
                  </div>
                  <div className="col-10 col-sm-10 col-md-11">Магазин</div>
                </Link>
                <Link to="/news" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                  <div className="col-2 col-sm-2 col-md-1">
                    <img alt="shop" src={newsoffcanvas} />
                  </div>
                  <div className="col-10 col-sm-10 col-md-11">Новости</div>
                </Link>
                <Link to="/privacy" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                  <div className="col-2 col-sm-2 col-md-1">
                    <img alt="privacy" src={privacy} />
                  </div>
                  <div className="col-10 col-sm-10 col-md-11">Лицензионное соглашение</div>
                </Link>
                <Link to="/showcasedownload" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                  <div className="col-2 col-sm-2 col-md-1">
                    <img alt="downloads" src={downloads} />
                  </div>
                  <div className="col-10 col-sm-10 col-md-11">Загрузки</div>
                </Link>
                <Link to="/contacts" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                  <div className="col-2 col-sm-2 col-md-1">
                    <img alt="contacts" src={contactsoffcanvas} />
                  </div>
                  <div className="col-10 col-sm-10 col-md-11">Контакты</div>
                </Link>
                <hr />

                {user && (
                  <>
                    <Link to="/cart" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                      <div className="col-2 col-sm-2 col-md-1">
                        <img alt="cartoffcanvas" src={cartoffcanvas} />
                      </div>
                      <div className="col-10 col-sm-10 col-md-11">Корзина</div>
                    </Link>
                    <Link to="/invoice" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                      <div className="col-2 col-sm-2 col-md-1">
                        <img alt="invoiceoffcanvas" src={invoiceoffcanvas} />
                      </div>
                      <div className="col-10 col-sm-10 col-md-11">Мои заказы</div>
                    </Link>
                    <Link to="/organization" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                      <div className="col-2 col-sm-2 col-md-1">
                        <img alt="organizationoffcanvas" src={organizationoffcanvas} />
                      </div>
                      <div className="col-10 col-sm-10 col-md-11">Мои юр. лица</div>
                    </Link>
                    <Link to="/license" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                      <div className="col-2 col-sm-2 col-md-1">
                        <img alt="cartoffcanvas" src={licenseoffcanvas} />
                      </div>
                      <div className="col-10 col-sm-10 col-md-11">Мои лицензии</div>
                    </Link>
                  </>
                )}
              </div>

              {user && (
                <div>
                  <hr />
                  <Link
                    to={""}
                    className="offcanvas-link text-white align-items-center row py-3"
                    onClick={(e) => {
                      dispatch(logoutUser());
                      localStorage.removeItem("token");
                      Cookies.remove("accessToken");
                      window.location.href = "/";
                    }}
                  >
                    <div className="col-2 col-sm-2 col-md-1">
                      <img alt="logoutoffcanvas" src={logoutoffcanvas} />
                    </div>
                    <div className="col-10 col-sm-10 col-md-11">Выйти</div>
                  </Link>
                </div>
              )}
              <div className="text-center pt-4">2022 OOO "НЕГОЦИАНТ"</div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {isLoginShow && (
        <LoginModal
          handleClose={() => {
            setIsLoginShow(false);
          }}
        />
      )}
    </>
  );
}

export default OffcanvasModule;
