import React from "react";

import cn from "classnames";
import s from "./Footer.module.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div className={s.footer}>
      <Container className={s.container}>
        <span className={s.text}>
          Перед использованием сайта советуем ознакомиться с{" "}
          <Link className={s.link} to="/terms">
            политикой конфиденциальности
          </Link>{" "}
        </span>
      </Container>
    </div>
  );
};

export default Footer;
