import $api from "../http";
import {Image} from "./ImageService";
import {AxiosResponse} from 'axios';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

export type Showcase = {
    showcaseid: string;
    licschemeid: string;
    licschemename: string;
    licmode: number;
    lictype: number;
    datefinish: Date;
    daysfrombuy: number;
    daysfromactivation: number;
    launchcount: number;
    connectcount: number;
    maxdiscount: number;
    recorddatecreate: Date;
    recordusernamecreate: string;
    recorddateupdate: Date;
    recordusernameupdate: string;
    productid: string;
    productcategory: number;
    activity: boolean,
    productname: string;
    nomenclatureid: string;
    nomenclaturename: string;
    price: number;
    images: Image[];
  };

export type ShowcaseList = {
    totalcount: number;
    items: Showcase[];    
  }

export default class ShowcaseService {
    static async getAll(page: number, limit: number): Promise<AxiosResponse<ShowcaseList>> {
        return $api.get<ShowcaseList>('/showcases',{
            params: pickBy(                                 
              {
                page,
                limit,
              },
              identity,
            ),
          })
    }
   
}

