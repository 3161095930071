import React from "react";
import { InvoiceService, IInvoice } from "../../services/InvoiceService";
import Container from "react-bootstrap/Container";
import { Pagination } from "../../components";
import InvoiceItem from "./InvoiceItem";
import EmptyBox from "../../components/EmptyBox";
import { Col, Row } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";

const InvoicePage: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [totalcount, setTotalcount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [items, setItems] = React.useState([] as IInvoice[]);
  const [error, setError] = React.useState();
  const limit = 40;
  const pageCount = Math.floor(totalcount / limit) + (totalcount % limit ? 1 : 0);

  React.useEffect(() => {
    InvoiceService.getAll(limit, currentPage)
      .then((res) => {
        setError(undefined);
        setLoading(false);
        setItems(res.data.items);
        setTotalcount(res.data.totalcount);
      })
      .catch((e) => {
        setError(e.message);
        setLoading(false);
        if (e.response) {
          console.log(e.response.data);
        } else if (e.request) {
          console.log(e.request);
        } else {
          console.log("Error", e.message);
        }
      });
  }, [loading, currentPage]);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  if (loading) return <Loader />;
  if (error) return <Container className="text-center">{error}</Container>;
  if (items.length === 0)
    return (
      <Container>
        <EmptyBox />
      </Container>
    );
  return (
    <Container className="my-5">
      <div className="d-none d-md-block">
        <h1>Мои заказы</h1>
        <hr className="text-dark" />
      </div>
      <div className="accordion open accordion-flush" style={{ border: "2px solid #00b4b6" }}>
        <div className="accordion-item" style={{ borderBottom: "2px solid grey" }}>
          <Row className="row accordion-header-invoice justify-content-between align-items-center text-center w-100" style={{ paddingRight: "40px", paddingLeft: "20px" }}>
            <Col xs={1} sm={1} md={1} lg={1} xl={1} className="p-0">
              Номер
            </Col>
            <Col xs={3} sm={4} md={6} lg={5} xl={4} className="p-0">
              Статус
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1} className="p-0">
              Кол-во
            </Col>
            <Col xs={3} sm={2} md={2} lg={2} xl={1} className="p-0 text-end">
              Стоимость
            </Col>
            <Col lg={2} xl={4} className="d-none p-0 d-lg-block">
              Дата создания
            </Col>
          </Row>
        </div>

        {items.map((item) => (
          <InvoiceItem key={item.invoiceid} {...item}></InvoiceItem>
        ))}
      </div>

      {pageCount > 1 && (
        <Container className="p-4">
          <Pagination currentPage={currentPage} onChangePage={onChangePage} pageCount={pageCount} />
        </Container>
      )}
    </Container>
  );
};

export default InvoicePage;
